import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const AboutUsPage = () => {
  return (
    <Layout>
      <SEO
        title="Om oss"
        description={`TopPicks är en rekommendationstjänst som drivs av ett litet team av skribenter som brinner för att hitta de bästa produkterna och tjänsterna på internet.`}
      />
      <div className="article-container">
        <h1 className="section-title">Om oss</h1>
        <p>
          TopPicks är en rekommendationstjänst som drivs av ett litet team av
          skribenter som brinner för att hitta de bästa produkterna och
          tjänsterna på internet. Våra talangfulla skribenter och redaktörer
          inkluderar:
        </p>
        <ul>
          <li>Linus Ahlström</li>
        </ul>
      </div>
    </Layout>
  )
}

export default AboutUsPage
